import { css } from '@linaria/core';
import { Media, RowRoot, ColumnRoot } from '@ard-online/component-styleguide';
import {
	Root as NavbarRoot,
	ActiveBar as NavbarActiveBar,
	ButtonElement,
} from '@ard-online/component-styleguide/dist/components/Navbar/styles';
import {
	Root as ButtonRoot,
	IconWrapper as ButtonIconWrapper,
} from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';

export const Header = {
	base: css`
		&& {
			width: 100%;
			padding: 0;
			margin: 0;
			z-index: 20;
			position: fixed;
			top: 0;
			height: 115px;
			background-color: rgb(var(--global-background));

			${Media('max', 'xs')} {
				height: 160px;
			}

			.${RowRoot.base} {
				display: flex;
				align-items: center;
				height: 70%;

				.${ColumnRoot.base} {
					padding: 0 0 0 5px;

					${Media('max', 'xs')} {
						padding: 0;
					}
				}
			}

			.${NavbarRoot.base} {
				> ul {
					${Media('max', 'xs')} {
						justify-content: center;
						margin-top: -10px;
						margin-bottom: 5px;
					}
				}

				.${NavbarActiveBar.base} {
					bottom: -25px;
					min-width: 48px;
					margin-left: -16px;
				}

				.${ButtonElement.base} {
					margin: 7px 20px 10px 0;

					.left {
						margin-right: 0;
					}

					&:last-child {
						margin-right: 0;
					}

					${Media('max', 'xs')} {
						margin-right: 10px;
					}

					.${ButtonRoot.base} {
						margin-left: 0;
						background: transparent !important;
						box-shadow:
							0 3px 6px rgba(var(--global-shadow), 0.16),
							0 3px 6px rgba(var(--global-shadow), 0.23) !important;
						overflow: inherit;
						justify-content: center;

						&:hover {
							box-shadow:
								0 3px 6px rgba(var(--global-shadow), 0.16),
								0 3px 6px rgba(var(--global-shadow), 0.23) !important;
						}

						.${ButtonIconWrapper.base} {
							border-radius: 48px;
							overflow: hidden;

							.${ImageRoot.base} {
								width: 48px;
								height: 48px;
								margin-right: 0;
								margin-left: 0;
							}
						}

						span {
							color: #001a4b;
							padding: 0;
							position: absolute;
							top: 40px;
							font-size: 0.65rem;
						}
					}
				}
			}
		}
	`,
};
export const HeaderShadow = {
	base: css`
		position: fixed;
		top: 0;
		height: 115px;
		width: 100%;
		box-shadow:
			0 3px 6px rgba(var(--global-shadow), 0.16),
			0 3px 6px rgba(var(--global-shadow), 0.23);
		opacity: 0;
		z-index: 9;
		pointer-events: none;

		${Media('max', 'xs')} {
			height: 160px;
		}
	`,
};

export const Logo = {
	base: css`
		&&& {
			padding: 15px 0 15px 10px;
			display: inline;
			transition: height 0.3s ease-in-out;

			svg {
				width: auto;
				height: 58px;
				background: transparent;
				fill: rgb(var(--global-text-secondary)) !important;

				> path {
					fill: rgb(var(--global-text-secondary)) !important;
				}
			}

			a {
				transition: transform 0.3s;

				&:hover {
					background: transparent !important;
					transform: scale(1.1);
				}
			}

			${Media('max', 'xs')} {
				display: flex;
				justify-content: center;
				height: 50px;
				margin: -10px auto 0;
			}
		}
	`,
};
