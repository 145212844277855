import { css } from '@linaria/core';
import { H2, Media, P } from '@ard-online/component-styleguide';

export const GlobalStyle = {
	base: css`
		/* stylelint-disable-next-line */
		:global() {
			body {
				font-family: var(--fontFamily);
				background-repeat: no-repeat;
				color: rgb(var(--global-background));
				background-color: rgb(var(--global-surface-secondary));
				margin: 0;
				transition: 0.3s background-color;
			}

			a {
				color: rgb(var(--global-background));
			}

			p {
				color: rgb(var(--global-text-secondary)) !important;
			}

			&:active,
			&:hover,
			&:visited {
				outline: none;
			}

			&:focus {
				outline: 2px solid rgb(var(--global-background-secondary));
				outline-offset: -2px;
			}
		}
	`,
};
export const Root = {
	base: css`
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	`,
};
export const ContentWrapper = {
	base: css`
		flex-grow: 1;
	`,
};
export const PageWrapper = {
	base: css`
		margin-top: 130px;

		${Media('max', 'xs')} {
			margin-top: 180px;
		}
	`,
};
export const Text = {
	base: css`
		padding-left: 15px;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		justify-items: flex-start;

		.${H2.base} {
			padding: 0;
			font-size: 2rem;

			a {
				font-size: inherit;
				color: rgb(var(--global-text-secondary)) !important;
				background: transparent !important;
				padding-left: 0;
				border-radius: 0;

				:hover {
					text-decoration: none;
				}

				span {
					padding: 0;
				}
			}
		}

		.${P.base} {
			margin-bottom: 10px;
		}
	`,
};
