import React, { useCallback, useEffect } from 'react';
import { BreakingnewsIcon, Column, H2, P, Row } from '@ard-online/component-styleguide';
import { Datetime, Label, Root } from './styles';
import useFetch from '../../../services/fetch/useFetch';
import { mapXMLBreakingNews } from '../../../services/fetch';
import { sendTeaserClick, sendTeaserImpression } from '../../../services/tracking';
import { PAGE_TITLES, WIDGET_TYPES } from '../../../configs/types';
import { useInView } from 'react-intersection-observer';
import { BreakingNewsItem } from '../../../services/fetch/mapXMLBreakingNews';
import { cx } from '@linaria/core';

type Props = {
	feed: string;
};

function BreakingNewsWidget({ feed }: Props) {
	/** Variables */
	const data: Array<BreakingNewsItem> = useFetch(feed, 'xml', mapXMLBreakingNews);
	const firstBreakingNews = data[0];

	const [rootRef, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const onTeaserClickMemo = useCallback(() => {
		sendTeaserClick(PAGE_TITLES.HOME, {
			widgetTitle: 'Eilmeldung',
			widgetIndex: -1,
			widgetId: WIDGET_TYPES.BREAKING_NEWS,
			teaserTitle: firstBreakingNews.title,
			teaserIndex: 0,
			teaserHref: firstBreakingNews.href,
		});
	}, [firstBreakingNews]);

	const onTeaserVisibleMemo = useCallback(() => {
		sendTeaserImpression(PAGE_TITLES.HOME, {
			widgetTitle: 'Eilmeldung',
			widgetIndex: -1,
			widgetId: WIDGET_TYPES.BREAKING_NEWS,
			teaserTitle: firstBreakingNews.title,
			teaserIndex: 0,
			teaserHref: firstBreakingNews.href,
		});
	}, [firstBreakingNews]);

	function formatNumber(number: number) {
		if (number < 10) {
			return `0${number}`;
		} else {
			return number;
		}
	}

	function getDate() {
		if (firstBreakingNews) {
			const date = new Date(firstBreakingNews.date);
			return `${formatNumber(date.getDate())}.${formatNumber(
				date.getMonth() + 1,
			)}.${date.getFullYear()} ・ ${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())} Uhr`;
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (inView && onTeaserVisibleMemo) {
			onTeaserVisibleMemo();
		}
	}, [inView, onTeaserVisibleMemo]);

	/** JSX */
	return (
		<>
			{firstBreakingNews && (
				<section className={cx(Root.base)} ref={rootRef}>
					<Row justifyContent="center" mx={0} my={0}>
						<Column all={11} xs={11} m={8}>
							<div className={cx(Label.base)}>
								<BreakingnewsIcon /> {firstBreakingNews.type}
								<span className={cx(Datetime.base)}>{getDate()}</span>
							</div>
							<a href={firstBreakingNews.href} onClick={onTeaserClickMemo}>
								<h2 className={cx(H2.base)}>{firstBreakingNews.title}</h2>
							</a>
							<p className={cx(P.base)}>{firstBreakingNews.teasertext}</p>
						</Column>
					</Row>
				</section>
			)}
		</>
	);
}

export default BreakingNewsWidget;
