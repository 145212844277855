import type { AnyFunction, AnyObject } from '../../types';
import getSlug from 'speakingurl';
import { getImageSrc, getImageSrcSet } from '../image';
import { mapTeaserType } from './mapTeaserType';
import getPictureCreditSourceForMt from './getPictureCreditSourceForMt';
import { Props } from '@ard-online/component-styleguide/dist/components/Teaser/Teaser';
import env from '../../configs/env';

export default function mapJSONItemsMTWithTeasers(
	href: string,
	onTeaserClick: AnyFunction,
	onTeaserVisible: AnyFunction,
) {
	return (rawJsonData: AnyObject) => mapJSONItemsMT(rawJsonData, href, onTeaserClick, onTeaserVisible);
}

export type Item = {
	coreAssetType: 'SEASON_SERIES' | 'SINGLE';
	id: string;
	type: string;
	title: string;
	shortTitle: string;
	mediumTitle: string;
	longTitle: string;
	duration: number;
	publicationService: {
		name: string;
	};
	show: {
		title: string;
	};
	links: {
		target: {
			href: string;
			urlId: string;
			partner: string;
		};
	};
	images: AnyObject;
};

function getShowType(item: Item) {
	switch (item.coreAssetType) {
		case 'SEASON_SERIES':
			return 'serie';
		case 'SINGLE':
			return 'film';
		default:
			return 'sendung';
	}
}

function mapJSONItemsMT(
	rawJsonData: AnyObject,
	href: string,
	onTeaserClick: AnyFunction,
	onTeaserVisible: AnyFunction,
) {
	const items = rawJsonData.teasers;
	const mappedItems: Array<Props & { image: { source: string } }> = [];

	function _encodeUrl(url: string): string {
		return getSlug(url, {
			lang: 'de',
			truncate: 300,
		});
	}

	function linkBuilder(item: Item) {
		const title = item.shortTitle ? `/${_encodeUrl(item.shortTitle)}` : '';
		const publicationServiceName = item.publicationService ? `/${_encodeUrl(item.publicationService.name)}` : '';
		const showTitle = item.show ? `/${_encodeUrl(item.show.title)}` : '';
		const id = item.links.target.urlId;
		const channelPath = item.links.target.partner !== 'ard' ? `${item.links.target.partner}/` : '';

		switch (item.type) {
			case 'ondemand':
			case 'poster':
				return `${env.MT_HOST}/video${showTitle}${title}${publicationServiceName}/${id}`;
			case 'event':
				return `${env.MT_HOST}/video/${id}`;
			case 'live':
				return `${env.MT_HOST}/live${publicationServiceName}/${id}`;
			case 'show':
				return `${env.MT_HOST}/${getShowType(item)}/${id}`;
			case 'compilation':
				return `${env.MT_HOST}/sammlung${title}/${id}`;
			case 'editorialPage':
				return env.MT_HOST + '/' + channelPath + id;
			case 'external':
				return item.links.target.href;
			default:
				return `${env.MT_HOST}/video${showTitle}${title}${publicationServiceName}/${id}`;
		}
	}

	if (items) {
		items.forEach((item: Item, index: number) => {
			for (const key in item.images) {
				item.images[key].srcset = getImageSrcSet(item.images[key].src, [
					[320, 320],
					[480, 480],
					[960, 960],
					[1280, 1280],
					[1600, 1600],
					[1920, 1920],
				]);
				item.images[key].src = getImageSrc(item.images[key].src, 960);
			}

			const trackingInfo = {
				teaserTitle: item.longTitle || item.mediumTitle || item.shortTitle,
				teaserIndex: index,
				teaserHref: linkBuilder(item),
				teaserType: mapTeaserType(item.type),
			};

			const onClick = () => onTeaserClick(trackingInfo);

			const onVisible = () => onTeaserVisible(trackingInfo);

			mappedItems.push({
				id: item.id,
				type: mapTeaserType(item.type),
				textPosition: 'onImage',
				textAlignment: 'center',
				duration: item.duration,
				label: 'Mediathek',
				target: item.type === 'external' ? '_blank' : '_self',
				shortTitle: item.shortTitle,
				mediumTitle: item.mediumTitle,
				longTitle: item.longTitle,
				href: linkBuilder(item),
				subline: item.show ? item.show.title : null,
				onVisible: onVisible,
				onClick: onClick,
				image: {
					...item.images,
					source: getPictureCreditSourceForMt(item.images),
				},
			});
		});
	}

	return mappedItems;
}
