import React, { useRef, useState } from 'react';
import {
	AlphaLogoWhite,
	ArdplusLogoWhite,
	ArteLogoWhite,
	BrLogoWhite,
	Button,
	Column,
	DasersteLogoWhite,
	DeutschewelleLogoWhite,
	DeutschlandradioLogoWhite,
	DreiSatLogoWhite,
	FunkLogoWhite,
	H2,
	HrLogoWhite,
	KikaLogoWhite,
	MdrLogoWhite,
	NdrLogoWhite,
	OneLogoWhite,
	PhoenixLogoWhite,
	RadiobremenLogoWhite,
	RbbLogoWhite,
	Row,
	SrLogoWhite,
	SwrLogoWhite,
	Tagesschau24LogoWhite,
	WdrLogoWhite,
	ZdfLogoWhite,
} from '@ard-online/component-styleguide';
import { ChannelLogo, Channels, FooterLogo, List, Listitem, Root } from './styles';
import footerLogo from '../../../assets/logos/wirSindDeins.svg';
import PictureCredits from './PictureCredits';
import type { AnyFunction } from '../../../types';
import { Link } from 'react-router-dom';
import env from '../../../configs/env';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function FooterWidget() {
	/** Variables */
	const [pictureCreditsVisible, setPictureCreditsVisible]: [boolean, AnyFunction] = useState(false);
	const pictureCreditsRef = useRef<HTMLDivElement>(null);

	function togglePictureCredits() {
		if (!pictureCreditsVisible) {
			setPictureCreditsVisible(true);
			window.requestAnimationFrame(() => {
				if (pictureCreditsRef && pictureCreditsRef.current) {
					const top = pictureCreditsRef.current.offsetTop;
					const scrollOptions: { top: number; behavior: ScrollBehavior } = {
						top: top - 200,
						behavior: 'smooth',
					};

					if (document.documentElement && 'scrollBehavior' in document.documentElement.style) {
						window.scrollTo(scrollOptions);
					} else {
						window.scrollTo(0, scrollOptions.top);
					}
				}
			});
		} else {
			setPictureCreditsVisible(false);
		}
	}

	/** JSX */
	return (
		<footer className={cx(Root.base)}>
			<Row justifyContent="center" mx={0} my={0}>
				<Column all={11} xs={11} m={8} p={0} py={3}>
					<Row mx={0} my={0}>
						<Column all={6} xs={4} s={3} p={0}>
							<nav aria-label="Die ARD">
								<h2 className={cx(H2.base)}>Die ARD</h2>
								<ul className={cx(List.base)} role="menu">
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href="https://www.ard.de/presse/"
											text="Presse"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href="https://www.ard.de/die-ard/"
											text="Das ist die ARD"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href="https://www.rundfunkbeitrag.de/"
											text="Rundfunkbeitrag"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
								</ul>
							</nav>
						</Column>
						<Column all={6} xs={4} s={3} p={0}>
							<nav aria-label="Mehr ARD">
								<h2 className={cx(H2.base)}>Mehr ARD</h2>
								<ul className={cx(List.base)} role="menu">
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href={`${env.MT_HOST}/programm`}
											text="Programm"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href={`${env.MT_HOST}/live`}
											text="Livestreams"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
								</ul>
							</nav>
						</Column>
						<Column all={6} xs={4} s={3} p={0}>
							<nav aria-label="Service">
								<h2 className={cx(H2.base)}>Service</h2>
								<ul className={cx(List.base)} role="menu">
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href="https://www.ard.de/kontakt/"
											text="Kontakt"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href="https://www.ard.de/karriere/"
											text="Karriere"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href="https://www.ard.de/home/ard/Newsletter_ARD_Mediathek/3676704/index.html"
											text="Newsletter"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											text="Bildnachweise"
											isActive={pictureCreditsVisible}
											ariaExpanded={pictureCreditsVisible}
											ariaControls="pictureCredits"
											onClick={togglePictureCredits}
											type={BUTTON_TYPES.TEXT}
											accessibility="menuitem"
										/>
									</li>
								</ul>
							</nav>
						</Column>
						<Column all={6} xs={4} s={3} p={0}>
							<nav aria-label="Information">
								<h2 className={cx(H2.base)}>Information</h2>
								<ul className={cx(List.base)} role="menu">
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											text="Nutzungsbedingungen"
											href="https://www.ard.de/nutzungsbedingungen"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											text="Datenschutz"
											href="https://ard.de/datenschutz"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											href="/einstellungen"
											text="Einstellungen"
											as={Link}
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
									<li className={cx(Listitem.base)} role="presentation">
										<Button
											text="Impressum"
											href="https://ard.de/impressum"
											accessibility="menuitem"
											type={BUTTON_TYPES.TEXT}
										/>
									</li>
								</ul>
							</nav>
						</Column>
					</Row>
					<Row my={0} mt={3} mb={3}>
						<Column all={12} pt={0}>
							<nav aria-label="Kanäle">
								<ul className={cx(Channels.base)} role="menu">
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="BR"
											isIconOnly
											href="https://www.br.de/"
											target="_blank"
											iconLeft={<BrLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="HR"
											isIconOnly
											href="https://www.hr.de/"
											target="_blank"
											iconLeft={<HrLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="MDR"
											isIconOnly
											href="https://www.mdr.de/"
											target="_blank"
											iconLeft={<MdrLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="NDR"
											isIconOnly
											href="https://www.ndr.de/"
											target="_blank"
											iconLeft={<NdrLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="RadioBremen"
											isIconOnly
											href="https://www.radiobremen.de/"
											target="_blank"
											iconLeft={<RadiobremenLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="RBB"
											isIconOnly
											href="https://www.rbb24.de/"
											target="_blank"
											iconLeft={<RbbLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="SR"
											isIconOnly
											href="https://www.sr.de/"
											target="_blank"
											iconLeft={<SrLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="SWR"
											isIconOnly
											href="https://www.swr.de/"
											target="_blank"
											iconLeft={<SwrLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="WDR"
											isIconOnly
											href="https://www.wdr.de/"
											target="_blank"
											iconLeft={<WdrLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="Deutsche Welle"
											isIconOnly
											href="https://www.dw.com/de/themen/s-9077"
											target="_blank"
											iconLeft={<DeutschewelleLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="Das Erste"
											isIconOnly
											href="https://www.daserste.de/"
											target="_blank"
											iconLeft={<DasersteLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="ONE"
											isIconOnly
											href={`${env.MT_HOST}/one`}
											target="_blank"
											iconLeft={<OneLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="Tageschau24"
											isIconOnly
											href="http://tagesschau24.de"
											target="_blank"
											iconLeft={<Tagesschau24LogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="ARD-alpha"
											isIconOnly
											href="https://www.ardalpha.de"
											target="_blank"
											iconLeft={<AlphaLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="3sat"
											isIconOnly
											href="https://www.3sat.de"
											target="_blank"
											iconLeft={<DreiSatLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="ARTE"
											isIconOnly
											href="https://www.arte.de"
											target="_blank"
											iconLeft={<ArteLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="Kika"
											isIconOnly
											href="https://www.kika.de"
											target="_blank"
											iconLeft={<KikaLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="PHOENIX"
											isIconOnly
											href="https://www.phoenix.de"
											target="_blank"
											iconLeft={<PhoenixLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="Funk"
											isIconOnly
											href="https://www.funk.net"
											target="_blank"
											iconLeft={<FunkLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="Deutschlandradio"
											isIconOnly
											href="https://www.deutschlandradio.de"
											target="_blank"
											iconLeft={<DeutschlandradioLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="ZDF"
											isIconOnly
											href="https://www.zdf.de"
											target="_blank"
											iconLeft={<ZdfLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
									<li className={cx(ChannelLogo.base)} role="presentation">
										<Button
											text="ARD Plus"
											isIconOnly
											href="https://www.ardplus.de"
											target="_blank"
											iconLeft={<ArdplusLogoWhite />}
											accessibility="menuitem"
										/>
									</li>
								</ul>
							</nav>
						</Column>
					</Row>
					<Row mx={0} my={0}>
						<Column all={12} p={0}>
							<img className={cx(FooterLogo.base)} src={footerLogo} alt="Wir sind deins." />
						</Column>
						{pictureCreditsVisible ? <PictureCredits ref={pictureCreditsRef} /> : null}
					</Row>
				</Column>
			</Row>
		</footer>
	);
}

export default FooterWidget;
