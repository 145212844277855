import { css } from '@linaria/core';
import { P, H6 } from '@ard-online/component-styleguide';
import { Root as SwitchRoot } from '@ard-online/component-styleguide/dist/components/Switch/styles';

export const Root = {
	base: css`
		.${H6.base} {
			padding: 0;
			margin-bottom: 30px;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			color: rgb(var(--global-text-secondary)) !important;
		}
	`,
};
export const List = {
	base: css`
		list-style: none;
		display: block;
		padding: 0;
		margin: 0;
		margin-bottom: 50px;
	`,
};
export const Listitem = {
	base: css`
		list-style-type: none;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding: 10px 0;
		margin: 0;
		border-bottom: 1px solid rgba(var(--global-text-secondary), 0.6);

		&:first-child {
			border-top: 1px solid rgba(var(--global-text-secondary), 0.6);
		}

		.${P.base} {
			margin-right: 10px;
		}

		.${SwitchRoot.base} {
			align-items: baseline;
		}
	`,
};
